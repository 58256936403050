const generateDaySuffix = (day) => {
    if (day >= 10 && day < 21)
        return 'th'
    const remainder = day % 10;

    switch (remainder) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th'
    }
}

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const appendZero = (value) => value < 10 ? `0${value}` : value;

const sortEvents = (ev1, ev2) => new Date(ev1.startDate) - new Date(ev2.startDate);

const createEvent = (startDate, endDate, title) => {
    const dateFormat = new Date(startDate);
    const end = new Date(endDate);
    const day = `${dateFormat.getDate()}${generateDaySuffix(dateFormat.getDate())} ${monthNames[dateFormat.getMonth()]}`
    const time = `${appendZero(dateFormat.getUTCHours())}:${appendZero(dateFormat.getMinutes())} - ${appendZero(end.getUTCHours())}:${appendZero(end.getMinutes())}`;

    return {
        day,
        time,
        title,
        startDate: startDate.toString(),
    }
}

const formatEventLine = (event) => `| ${event.day} | ${event.time} | ${event.title} |`;

export const createEventHelper = (eventArray) => {
    let largestDay = 0;
    let largestTitle = 0;
    let eventHelper = [];
    for (let i = 0; i < eventArray.length; i++) {
        const summary = eventArray[i].summary ? eventArray[i].summary.value : "Recurring event"
        const e = createEvent(eventArray[i].dtstart.value, eventArray[i].dtend.value, summary);
        largestDay = e.day.length > largestDay ? e.day.length : largestDay;
        largestTitle = e.title.length > largestTitle ? e.title.length : largestTitle;
        eventHelper.push(e)
    }

    const filledEvents = eventHelper.map((ev) => ({
        day: `${ev.day}${new Array(largestDay - ev.day.length + 1).fill('').join(' ')}`,
        time: ev.time,
        title: `${ev.title}${new Array(largestTitle - ev.title.length + 1).fill('').join(' ')}`,
        startDate: ev.startDate
    }));
    return filledEvents.sort(sortEvents);
}

export const displayEvents = (events) => events.map((e, index) =>
    <div key={index}><pre>{formatEventLine(e)}</pre></div>
)