import { useState } from 'react';
import ical from 'cal-parser';
import { Alert, styled } from "@mui/material";
import { CalendarToday } from '@mui/icons-material';
import { Container, TitleDiv, SubTitleDiv, Monospace, StyledButton, TextDiv, CopyIcon } from './App.styles';
import { createEventHelper, displayEvents } from './helper';

const App = () => {
  const [events, setEvents] = useState([]);
  const [invalidFile, setInvalidFile] = useState(false);

  const createEvents = (eventArray) => {
    const eventHelper = createEventHelper(eventArray);
    setEvents(eventHelper);
  }

  const showFile = async (ev) => {
    ev.preventDefault();
    if (ev.target.files[0].type !== 'text/calendar') {
      setInvalidFile(true);
      setEvents([]);
      return;
    }

    setInvalidFile(false);

    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      const data = ical.parseString(text);
      createEvents(data.events);
    };

    reader.readAsText(ev.target.files[0])
  }

  const copyToClipboard = () => {
    let text = document.getElementById('textDiv').innerText;
    navigator.clipboard.writeText(text)
  }

  const ConditionalAlert = styled(Alert)({
    display: invalidFile ? 'flex' : 'none',
  })

  return (
    <Container>
      <TitleDiv><CalendarToday />Calendar to <Monospace>monospace</Monospace> Text Parser<CalendarToday /></TitleDiv>
      <SubTitleDiv>When saving the .ics file from Outlook it's a good idea to select 'more options' and choose:<ul><li>'7 days ahead'</li><li>'Limited details'</li></ul> or the tool won't have enough information.</SubTitleDiv>
      <StyledButton
        variant="contained"
        component="label"
      >
        Upload File
        <input
          type="file"
          hidden
          onChange={(e) => showFile(e)}
        />
      </StyledButton>
      <TextDiv id="textDiv">
        {events.length > 0 && '```'}
        {displayEvents(events)}
        {events.length > 0 && '```'}
      </TextDiv>
      <ConditionalAlert severity="error">
        File needs to be of type calendar! (.ics)
      </ConditionalAlert>
      <StyledButton onClick={copyToClipboard} variant="contained">
        Copy to Clipboard <CopyIcon />
      </StyledButton>
    </Container>
  );
}

export default App;
