import { Button, styled } from "@mui/material";
import { ContentCopy } from '@mui/icons-material';

export const Container = styled('div')({
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto',
    placeItems: 'center',
    paddingTop: '5em',
    gap: '3em'
})

export const TitleDiv = styled('div')({
    display: 'grid',
    gap: '1em',
    alignItems: 'end',
    gridTemplateColumns: 'repeat(5,auto)',
    fontFamily: 'Verdana',
    fontWeight: 'bold',
    color: '#207567'
})

export const SubTitleDiv = styled('div')({
    display: 'block',
    gap: '1em',
    alignItems: 'end',
    gridTemplateColumns: 'repeat(5,auto)',
    fontFamily: 'Verdana',
    fontWeight: 'bold',
    color: '#207567',
    width: '30%',
    textAlign: 'center',
})

export const Monospace = styled('span')({
    fontFamily: 'monospace'
})

export const TextDiv = styled('div')({
    fontFamily: 'monospace',
    width: '80%',
    maxWidth: '1000px',
    height: '50vh',
    overflowY: 'scroll',
    borderRadius: '1.5em',
    border: '2px solid #207567',
    padding: '1em',
    fontSize: '1em',
    background: 'white'
})

export const StyledButton = styled(Button)({
    background: '#207567',
    '&:hover': {
        background: '#358873'
    }
})

export const CopyIcon = styled(ContentCopy)({
    paddingLeft: '.5em',
    width: '.7em'
})